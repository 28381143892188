<template>
  <section class="section keep-footer-off-page">
    <div
      class="p-5 container column box is-one-third-desktop is-half-tablet is-one-quarter-widescreen"
    >
      <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
        <!-- use form so enter to submit works -->
        <form @submit.prevent="handleSubmit(resetPassword)" novalidate="true">
          <!-- email stuff -->
          <ValidationProvider
            rules="required|email"
            name="Email"
            v-slot="{ errors }"
          >
            <b-field
              label="Email"
              label-position="on-border"
              :type="{ 'is-danger': errors[0] }"
              :message="errors"
            >
              <b-input
                type="email"
                placeholder="john.smith@email.com"
                v-model="email"
              ></b-input>
            </b-field>
          </ValidationProvider>
          <!-- errors -->
          <b-message v-if="errorCode" type="is-danger" class="mt-3">
            {{ errorCode }}
          </b-message>
          <!-- submit -->
          <button type="submit" class="mt-3 button">
            Request New Password
          </button>
        </form>
      </ValidationObserver>
    </div>
  </section>
</template>

<script>
import {
  getAuth,
  onAuthStateChanged,
  sendPasswordResetEmail,
} from "firebase/auth";
import {
  setInteractionMode,
  ValidationObserver,
  ValidationProvider,
} from "vee-validate";

setInteractionMode("passive");

export default {
  name: "reset-request-view",
  components: {
    ValidationObserver,
    ValidationProvider,
  },

  data() {
    return {
      email: "",
      error: "",
      errorCode: "",
    };
  },

  methods: {
    resetPassword() {
      const auth = getAuth();
      sendPasswordResetEmail(auth, this.email)
        .then(() => {
          // Password reset email sent!
          this.$buefy.toast.open("Password reset email sent");
          this.$router.replace({ name: "Login" });
        })
        .catch((error) => {
          this.errorCode = error.code;
          this.error = error.message;
        });
    },
  },

  created() {
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      // Check for user status
      if (user) {
        this.$router.replace(
          this.$route.query.redirect || { name: "Dashboard" }
        );
      }
    });
  },
};
</script>
